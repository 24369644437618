/*
** Base Stylesheet
*****************
** General
*****************/

img, iframe {
	max-width: 100%;
}

.btn-secondary {
	color: white;
	
	&:hover {
		color: white;		
	}
}

.btn-green {
	@include button-variant(
		$green,
		$green,
		$light,
		$primary,
		$primary
	);
}

.btn-secondary-light {
	@include button-variant(
		$secondary-light,
		$secondary-light,
		$dark,
		$primary,
		$primary
	);
}

.btn-rounded {
	padding: 10px 20px 10px 14px;
	border-radius: 100px;
	line-height: 1;
}

a {
	color: $secondary;
	
	&:hover {
		color: darken($secondary, 20%);
	}
}

.textarea-large {
	min-height: 120px!important;
}

.grecaptcha-badge { visibility: hidden !important; }


/*****************
** Content
*****************/


#appContainer {
	color: $dark;
	min-width: 0;
}

.home-sponsors {

    .sponsor-images {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        align-items: center;
        justify-content: center;
    }
}

/*****************
** Headers
*****************/


h3 {
	font-size: 1.75rem;
}


/*****************
** Navigation
*****************/

.admin-nav {
	background: #161616;
}

.user-controls {
	flex: 0 0 100%;
	text-align: center;
	
	.uc-iw {
		padding: .25rem 1rem;
		display: inline-block;
		border: 3px solid $secondary;
		border-radius: 12px;	
	}
}

.navbarLogoWrap {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
}

.expand_caret {
	float: right;
	font-size: 24px;
	transition: all ease .3s;
}
a[aria-expanded='true'] > .expand_caret {
    transform: rotate(-180deg);
	margin-top: -3px;
}

.nav-collapse {
	margin-left: 1em;
	font-size: 18px;
}

#appContainer {
	
	.navbar {
		
		.navbar-toggler {}
	}
}

.nav {
	
	a {
		color: $dark;
	}
	.current-nav-item {
		background: $secondary;
		
		a { 
			color: white;
		}
	}
} 

/*****************
** Profile Index Page
*****************/

#bio_excerpt {
	height: 272px;
}

#profileImageWrapper {
	width: 200px;
	height: 200px;
	position: relative;
	margin: 17px auto;
	overflow: hidden;
	
	#profileImage {
		position: absolute;
		z-index: 9;
		top: 0;
		left: 0;
	    transform: scale(0.67);
	    max-width: none;
	    transform-origin: top left;
	}
}

#cropperWrapper {
	
	#cropperImage {
		display: block;
		visibility: hidden;
	}
}

.addVocation {
	cursor: pointer;
	color: $primary;
}

/*****************
* Member's Page
*****************/

#contactModal {
	
	textarea {
		height: 190px;
	}
}

/*****************
** Admin Page Styles
*****************/

.profiles-table {
	.pp-wrapper {
		flex: 0 0 33.33%;
	}
	.pp-icon {
		line-height: 1;
		padding: 10px;
		cursor: pointer;
		display: inline-block;
		border-radius: 5px;
		transition: all ease .25s;
		color: $primary;
		
		&:hover {
			color: white;
		}	
		.bi {
			font-size: 1.5rem;
		}
	}
	.pp-profile-view {
		
		&:hover {
			background: $primary;
			color: white;
		}
	}
	.pp-profile-approve {
		
		&:hover {
			background: $success;
			color: white;
		}
	}
	.pp-profile-deny {
		
		&:hover {
			background: $danger;
			color: white;
		}
	}
	.pp-profile-info {
		
		&:hover {
			background: $blue-300;
			color: white;
		}
	}
}

#postImageWrapper {
	width: 500px;
	position: relative;
	margin: 0 auto;
	overflow: hidden;
	
	#postImage {
		position: absolute;
		z-index: 9;
		top: 0;
		left: 0;
	    transform: scale(1.666);
	    max-width: none;
	    transform-origin: top left;
	}
}

/*****************
** Vocation Admin Styles
*****************/ 

.update-success {
	animation-duration: 1s;
	animation-name: update-success; 
	
	td {
		animation-duration: 1s;
		animation-name: update-success; 
	}
}

@keyframes update-success {
	
	25% {
		background: #58dd9f;
	}
	
	50% {
		background: var(--bs-table-bg);
	}
	
	75% {
		background: #58dd9f;
	}
	
	100% {
		background: var(--bs-table-bg);
	}
}

.merge-active {
	
	td {
		background: $blue-200;
	}
}

/*****************
** Homepage Styles
*****************/

.home-banner {
    margin: 1rem 0;
    
    .hb-wrapper {
        border-radius: 1rem;
        padding: 2rem;
        min-height: 400px;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;

        &:after {
            content: '';
            background: rgba(0, 0, 0, .5);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
        }
        .hbw-inner {
            color: white;
            max-width: 700px;
            position: relative;
            z-index: 9;
        }
    }
}


/*****************
** Content Styles
*****************/

.post-content {
	
	ul, ol {
		display: inline-block;
	}
}

/*****************
** About Page Styles
*****************/

.team-section {
	
	.ind-team-member {
		line-height: 1;
		margin: 1rem 0;
		
		h4 {
			margin: .5rem 0 0;
		}
		p {
			margin: 0;
		}
	}
}

/*****************
** Forum Styles
*****************/

.forum-layout {
	
	.forum-index-container {
		
		.alert-success {
			color: white;
		    background-color: $green;
		    border-color: $green;
		}
	}
	
	main {
		
		.forum-container {
			background: white;
		}
	}
	
	#appContainer {
		background: $light;
		color: $dark;
	}
	
	.mobile-controls {
		
		.post-new, .post-search {
			
			i {
				font-size: 1.75rem;
				margin-right: 10px;
				color: white;
				vertical-align: middle;
				transition: color .15s ease;
			}
			.btn {
				max-width: 160px;
				text-align: left;
				display: flex;
    			align-items: center;
				
				&:hover, &:focus, &:active {
					i {
						color: white;
					}
				}
			}
		}
	}
	
	footer {
		
		.footer-cr {
			background: $dark!important;
			color: white;
		}
	}
	
}

.forum-index-intro {
	background: white;
	padding: 2rem 2rem 1rem;
}

.new-post-controls {
	.photo-upload-controls {
		position: relative;
		
		#clearUpload {
			position: absolute;
			top: -13px;
			right: 0;
			display: none;
			line-height: 0;
			z-index: 99;
			background: white;
			border-radius: 100px;
			padding: 1px 1px 0;
		}
		
		#postImageWrapper {
			width: 100%;
		}
		#post_image {
			display: none;
		}
		#post_image_label {
			background: $light;
			border: 1px solid #707070;
			height: 250px;
			width: 100%;
			text-align: center;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center; 
			color: #707070;
		}
	}
	.post-content-controls {
		
	}
}

.forum-category {
	display: flex;
	border: 1px solid #E2E2E2;
	border-radius: 50px;
	overflow: hidden;
	transition: all ease .6s;
	cursor: pointer;
	
	.forum-category-image {
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto 50%;
		flex: 0 0 25%;
		background-color: #E2E2E2;
	    opacity: .6;
    	transition: all ease .6s;
	}
	.forum-category-content {
		flex: 1 1 auto;
		font-size: 1.125rem;
		padding: 1rem;
		text-align: center;
	}
}

.index-categories {
	.forum-category {
		
		.forum-category-image {
			opacity: 1;
			background-color: $primary;
		}
		.forum-category-content {
			background: white;
		}
	}
}

.forum-category-active {
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	transform: scale(1.03);
	
	.forum-category-image {
	    opacity: 1;
	    background-color: $primary;
	}
}

.index-posts {
	
	.forum-post {

		.forum-post-inner {
			border-radius: 15px;
			overflow: hidden;
			background: white;
			box-shadow: 0 0 10px rgba(0, 0, 0, .1);
			position: relative;
		}
		.forum-post-image {
			
			img {
				width: 100%;
			}
		}
		.forum-post-content {
			padding: 1rem;
			position: relative;
			
			.forum-post-profile {
				background-color: #F3F5F5;
				position: absolute;
				right: 10px;
				top: -25px;
				height: 50px;
				width: 50px;
				background-size: cover;
				background-position: center;
				border-radius: 25px;
			}
			p, h4 {
				margin: 0;
			}
			p {
				font-size: .875rem;
				color: #707070;
			}
			h4 {
				font-family: $font-family-base;
				font-weight: 400;
				font-size: 1.25rem;
			}
		}
	}
}

.forum-post-container {
	
	.post-content {
		
		.post-author-photo {
			img {
				max-width: 60px;
				border-radius: 30px;
				background-color: #F3F5F5;
			}
		}
		.post-author-name {
			font-size: 1.125rem;
			color: #707070;
		}
		.post-header {
			
			.post-header-title {
				h2 {
					margin: 0;
				}
				p {
					margin: 0;
					font-size: .875rem;
				}
			}
			.post-header-category {
				
				.forum-category {
					border-radius: 15px;
					max-width: 400px;
					margin: 0 auto;
					
					.forum-category-image {
						background-color: transparent;
					}
				}
			}
			
		}
	}
	.post-comments {
		
		.post-comment {
			border: 1px solid $green;
			border-radius: 15px;
			position: relative;
			
			.post-comment-delete {
				position: absolute;
				line-height: 1;
				right: 10px;
				top: 10px;
				color: $danger;
				border-radius: 15px;
				display: flex;
				align-items: center;
				justify-content:center;
				cursor: pointer;
				overflow: hidden;
				
				.post-comment-delete-icon {
					padding: 3px;
					i {
						vertical-align: middle;
					}
				}
				.post-comment-delete-text {
					width: 0px;
					font-size: .75rem;
					line-height: 1;
					transition: all ease .5s;
					
					input[type='submit'] {
						background: none;
						color: $danger;
						border: 0;
						padding: 3px 8px;
					}			
				}
				.post-comment-delete-text-active {
					width: 135px;	
				}
			}
			
			.post-comment-photo {
				
				img {
					max-width: 40px;
					border-radius: 20px;
					background-color: #F3F5F5;
				}
			}
			.post-comment-content {
				font-size: 1rem;
				
				h5 {
					font-weight: 400;
					font-size: 1.25rem;
					
					span {
						font-size: 1rem;
						color: #707070;
						font-weight: 300;
					}
				}
			}
		}
	}
}

.post-card {
	
	.post-card-inner {
		display: flex;
		border-radius: 15px;
		overflow: hidden;
	}
	.post-card-title {
		flex: 1;
		background: #F3F5F5;
		line-height: 1;
		display: flex;
		align-items: center;
		padding: .25rem 1rem;
	}
	.post-card-link {
		background: $primary;
		display: flex;
		align-items: center;
		
		a {
			display: inline-block;
			color: white;
			text-decoration: none;
			padding: 1rem;
		}
	}
}

.post-delete-initial {
	width: auto;
	font-size: .875rem;
	padding: .4rem 1.5rem .25rem;
}
.post-delete {
	
	input[type=submit] {
		font-size: .875rem;
	}
}

@media all and (max-width: 1199px) {
	.forum-layout .forum-sidebar-nav {
		
		.navbar-toggler {
			display: none;
		}
		#navBar {
			display: flex;
		}
	}
}


/*****************
** Footer Styles
*****************/
 
.footer-main {
	padding: 3rem 0 2rem;
	background: #242424;
	color: white;
	
	.footer-nav {
		
		.fn-iw {
			position: relative;
			padding-bottom: 2rem;
			margin-bottom: 2rem;
			
			&:after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 200px;
				height: 1px;
				background: white;
			}
		}
	}
	.footer-sm {}
	.footer-disc {
		font-size: 1rem;
		margin-top: 2rem;	
	}
}
