/*
** 1200+ Stylesheet
****
** General
*/



/*
** Layout
*/

#app {
	display: flex;
	min-height: 100vh;

	#appSidebar {
		flex: 0 0 300px;
		
		.leftSidebar {
			visibility: visible!important;
			transform: none;
			position: static;
			height: 100%;
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			
			.ls-logo {
				img {
					max-width: 100%;
				}
			}
			
			.ls-close-button {
				display: none;
			}
		}
		.offcanvas-backdrop {
			display: none;
		}
	}

	#appContainer {
		flex: 1;
	}	
	
}


/*****************
** Header Styles
*****************/

#headerMain {
	
	.navbarLogoWrap {
		flex: 0 0 300px;
	}
}

/*****************
** Forum Styles
*****************/

.forum-layout {
	
	#app {
		flex-wrap: wrap;
		
		#appSidebar {
			flex: 0 0 220px;
		}
	}
	
	.forum-right-sidebar {
		flex: 0 0 300px;
		
		.profile-info {
			
			.profile-info-photo {
				
				img {
					max-width: 70px;
					border-radius: 50px;
				}
			}
			.profile-info-name {
				
				h5 {
					color: #707070;
					line-height: 1;
					font-family: $font-family-base;
					font-weight: 400;
				}
				a {
					text-decoration: none;
					color: #707070;
				}
			}
		}
	}
	
	.mobile-controls {}
	
	#leftSidebar {
		max-width: 220px;
		
		.ls-logo {
			text-align: center;
			
			img {}
		}
		.forum-sidebar-nav {
			.navbar-toggler {
				font-size: 3.5rem;
			    display: block;
			    margin: 0 auto;
			}		
		}
	}
	
	footer {
		flex: 1 1 100%;
	}
	
}

.forum-category {
	
	.forum-category-image {}
	.forum-category-content {
		font-size: .75rem;
		padding: 1rem .5rem;
		line-height: 1;
	}
}

.forum-post-container {
	
	.post-content {
		
		.post-header {
			
			.post-header-category {
				min-width: 260px;
			}
			
		}
	}
}

@media all and (min-width: 1800px) {
	
	.forum-container {
		border-radius: 15px;
	    margin: 4rem auto;
	    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	    max-width: 1150px;
	    padding: 2.5rem!important;
	}
	
}


/*
** Navigation
*/

#appContainer {
	
	.navbar {
		
		.navbar-toggler {}
	}
}