/****************
** 1400+ Stylesheet
*****************
** General Styles
*****************/

.container-maxwrap {
	max-width: 1920px;
	padding-left: 3%;
	padding-right: 3%;
}


/*****************
** Header Styles
*****************/

#headerMain {
	
	.navbarLogoWrap {
		flex: 0 0 auto;
	}
}


/*****************
** Homepage Styles
*****************/


.home-banner {

    .hb-wrapper {
        padding: 2rem 6rem;
        min-height: 700px;
    }
}


/*****************
** Left Sidebar
*****************/

.leftSidebar {

	.ls-nav {
		font-size: 1.25rem;
	}
	
}

/*****************
** Forum Styles
*****************/

@media all and (min-width: 1600px) {
	
	.forum-index-container {
		padding: 3rem 4rem;
	}
	
	.index-categories {
		.forum-category {

			.forum-category-image {}
			.forum-category-content {
				font-size: 1rem;
			}
		}
	}
	
}


/*****************
** Navigation Styles
*****************/

#navbarMain {
	
	.navbar-nav {
		margin: 0 2rem;
		justify-content: flex-end;
		width: 100%;
		
		.nav-item {
			margin-right: 0;
		}
	}
}
