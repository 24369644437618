/*
** 992+ Stylesheet
*/

.container-smwrap {
	max-width: 1100px!important;
	width: 92%;
}


/*****************
** Navigation Styles
*****************/

#navbarMain {
	
	.navbar-nav {
		margin-left: auto;
		
		.nav-item {
			margin-right: 30px;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.user-controls {
	flex: 0 0 auto;
	
	.uc-iw {
		border: 0;
		margin-left: 2rem;
		padding-right: 0;
	}
}

/*****************
** Header Styles
*****************/

#headerMain {
	margin: 1rem 0;
	
	.navbarLogoWrap {
		flex: 0 0 200px;
	}
}

/*****************
** Homepage Styles
*****************/

.home-banner {

    .hb-wrapper {
        min-height: 600px;
    }
}

/*****************
** Forum Styles
*****************/

.index-categories {
	.forum-category {
		border-radius: 15px;
		box-shadow: 0 0 10px rgba(0, 0, 0, .1);
		
		.forum-category-image {}
		.forum-category-content {
			font-size: .875rem;
			padding: 1.75rem 1rem;
		}
	}
}

.post-image {
	display: flex;
	flex-direction: column;
}