// Body
$body-bg: #f8fafc;

// Typography
$font-family-base: forma-djr-display, sans-serif;
$font-size-base: 1.25rem;
$font-weight-base: 500;
$line-height-base: 1.6;
$headings-font-family: forma-djr-display, sans-serif;
$headings-font-weight: 400;

// Theming
$primary: #00C3FF;
//llight: #F3F5F5;
$secondary: #FF00A4;
$secondary-light: #F6D38D;
//$green: #A3D2CB;
$dark: #000000; 