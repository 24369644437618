/*****************
** Homepage Styles
*****************/

.homeSliderWrapper {
	
	.carousel-inner {
		
		.carousel-item {
			min-height: 500px;
		}
		.carousel-text {
			font-size: 1.25rem;
			
			h1, h2, h3 {
				font-size: 2em;
			}
			
			.overlay {}
			.ct-inner {}
		}
	}
	.carousel-control-prev, .carousel-control-next, .carousel-indicators {}
	.carousel-control-prev, .carousel-control-next {}
}


/*****************
** Footer Styles
*****************/
 
.footer-main {
	
	.footer-nav {
		
		.fn-iw {
			
			&:after {
				top: 0;
				right: 0;
				bottom: 0;
				left: unset;
				width: 1px;
				height: 100%;
			}
		}
	}
	.footer-sm {}
	.footer-disc {
		font-size: 1rem;
		margin-top: 2rem;	
	}
}